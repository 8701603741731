<template>
    <v-row>
        <v-col
        cols="12"
        md="12">
            <table-resumo-pagamentos></table-resumo-pagamentos>
        </v-col>
    </v-row>
</template>

<script>
import TableResumoPagamentos from './TableResumoPagamentos.vue';

export default {
    components: {
        TableResumoPagamentos,
    },
    setup() {
        
    },
}
</script>