<template>
  <v-card>
    <v-card-title>
      <v-icon>
        {{ icons.mdiCash100 }}
      </v-icon>
      <span class="me-3">&nbsp; Extrato de pagamentos</span>
      <v-spacer></v-spacer>
      <!-- <span class="text-xs text--disabled cursor-pointer">Ver todas</span> -->
    </v-card-title>

    <v-data-table
      v-if="this.showTable"
      :headers="headers"
      :items="this.empreendimentosresumo"
      item-key="codigoObra"
      class="table-rounded"
      hide-default-footer
      disable-sort
      fixed-header
      no-data-text="Nenhum extrato encontrado"
      no-results-text="Nenhum extrato encontrado"
    >
      <!-- id -->
      <template #[`item.id`]="{item}">
        <v-icon>
          {{ icons.mdiOfficeBuildingMarkerOutline }}
        </v-icon>
        <div class="d-flex flex-column">
          <small>{{ item.post }}</small>
        </div>
      </template>
      <!-- empreendimento -->
      <template #[`item.valor`]="{item}">
        <v-chip
          small
          class="font-weight-medium"
        >
          {{ item.valor }}
        </v-chip>
      </template>
      <!-- total parcelas -->
      <template #[`item.valorPago`]="{item}">
        <v-chip
          small
          outlined
          :color="statusColor['green']"
          class="font-weight-medium"
        >
          {{ item.valorPago }}
        </v-chip>
      </template>

      <!-- Parcelas pagas -->
      <template #[`item.totalparcelas`]="{item}">
        <v-chip
          small
          :color="statusColor['warning']"
          class="font-weight-medium"
        >
          {{ item.totalparcelas }}
        </v-chip>
      </template>

      <!-- total -->
      <template #[`item.valorTotal`]="{item}">
        <v-chip
          small
          outlined
          :color="statusColor['warning']"
          class="font-weight-medium"
        >
          {{ item.valorTotal }}
        </v-chip>
      </template>
      <!-- total a pagar -->
      <template #[`item.valorAPagar`]="{item}">
        <v-chip
          small
          outlined
          :color="statusColor['primary']"
          class="font-weight-medium"
        >
          {{ item.valorAPagar }}
        </v-chip>
      </template>
      <!-- Ação -->
      <template #[`item.acoes`]="{item}">
        <v-col cols="auto">
          <v-dialog
            v-model="dialogs[item.id]"
            transition="dialog-top-transition"
            :retain-focus="false"
            max-width="1000px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                style="height: 20px !important;width: 80px !important;"
                small
                icon
                color="white"
                class="btn-bol text-center"
                v-on="on"
              >
                <small>{{ item.acoes }}</small>
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-toolbar
                  color="primary white--text"
                >
                  Resumo detalhado
                </v-toolbar>
                <v-card-text>
                  <v-row class="pa-8">
                    <v-col
                      cols="6"
                      lg="12"
                    >
                      <v-row>
                        <v-col
                          cols="6"
                          md="8"
                        >
                          <p
                            class="text-xl-h6 font-weight-medium"
                            style="margin-bottom: 0;"
                          >
                            {{ item.empreendimento }}
                          </p>
                          <p
                            class="text-sm-body-2 font-weight-medium"
                            style="margin: 0;"
                          >
                            {{ item.identificador }}
                          </p>
                        </v-col>
                        <v-col
                          cols="4"
                          md="3"
                        >
                          <p
                            class="text-sm-body-2"
                            style="margin: 0;"
                          >
                            Data de calculo: {{ item.datadecalculo }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <p class="text-lg-h6">
                        Parcelas por tipo
                      </p>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Tipo
                              </th>
                              <th class="text-left">
                                Descrição
                              </th>
                              <th class="text-left">
                                Qtd. a pagar
                              </th>
                              <th class="text-left">
                                Qtd. paga
                              </th>
                              <th class="text-left">
                                Saldo devedor
                              </th>
                              <th class="text-left">
                                Total pago
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="p in item.parcelasportipo"
                              :key="p.n"
                            >
                              <td>{{ p.tipo }}</td>
                              <td>{{ p.descricao }}</td>
                              <td>{{ p.quantidadeApagar }}</td>
                              <td>{{ p.quantidadePaga }}</td>
                              <td>{{ p.totalparcelaApagar }}</td>
                              <td>{{ p.totalparcelaPaga }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <p class="text-lg-h6">
                        Totais pago
                      </p>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Parcelas adiantadas
                              </th>
                              <th class="text-left">
                                Parcelas atrasadas
                              </th>
                              <th class="text-left">
                                Juros atraso
                              </th>
                              <th class="text-left">
                                Multa
                              </th>
                              <th class="text-left">
                                Valor pago
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="p in item.totaisrecebido"
                              :key="p.n"
                            >
                              <td>{{ p.parcelasrecebidasadiantadas }}</td>
                              <td>{{ p.parcelasrecebidasatrasadas }}</td>
                              <td>{{ p.totaisparcelasrecebida }}</td>
                              <td>{{ p.valorjurosatraso }}</td>
                              <td>{{ p.valormulta }}</td>
                              <td>{{ p.valorrecebido }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <p class="text-lg-h6">
                        Totais a Pagar
                      </p>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Parcelas a pagar
                              </th>
                              <th class="text-left">
                                Saldo devedor
                              </th>
                              <th class="text-left">
                                Totais de parcelas
                              </th>
                              <th class="text-left">
                                Parcelas atrasadas
                              </th>
                              <th class="text-left">
                                Juros
                              </th>
                              <th class="text-left">
                                Multa
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="p in item.totaisareceber"
                              :key="p.n"
                            >
                              <td>{{ p.valorprincipal }}</td>
                              <td>{{ p.saldodevedor }}</td>
                              <td>{{ p.totalparcelas }}</td>
                              <td>{{ p.parcelasatrasadas }}</td>
                              <td>{{ p.valorjuros }}</td>
                              <td>{{ p.multa }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    outlined
                    color="light-green"
                    text
                    @click="gerarPDFDemonstrativoPgto(item.codigoEmpresa, item.numeroVenda, item.codigoObra)"
                  >
                    Gerar PDF
                  </v-btn>
                  <v-btn
                    outlined
                    color="primary"
                    text
                    @click="closeDialogs(item.id)"
                  >
                    Fechar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </template>

      <template #[`item.parcelas`]="{item}">
        <v-btn
          style="height: 20px !important;width: 90px !important;"
          small
          icon
          color="white"
          class="btn-bol text-center"
          @click="verParcelas(item.codigoEmpresa, item.numeroVenda, item.codigoObra, item.empreendimento, item.identificador)"
        >
          <small>Parcelas</small> <small style="visibility: hidden; position: absolute;">{{ item.parcelas }}</small>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiCash100, mdiOfficeBuildingMarkerOutline, mdiEyeOutline } from '@mdi/js'

import Cookie from 'js-cookie'
import moment from 'moment'
import UsuarioStore from '../../store/Usuario'
import Strings from '@/common/strings'

export default {
  data() {
    return {
      dialogs: Array,
    }
  },
  mounted() {
    this.momentF = moment

    this.$loading(true)

    UsuarioStore.resumovenda(JSON.parse(Cookie.get('payloadEmpreendimentos')), Strings.imobiliariaId).then(result => {
      const dialogsShow = []

      result.data.map((val, index) => {
        dialogsShow[index] = false
      })
      this.dialogs = dialogsShow

      result.data.forEach((val, ind) => {
        const parcelasPorTipo = []

        val.parcelasportipo.forEach((v, idx) => {
          const payload = {
            n: idx,
            tipo: v.tipoParcela,
            descricao: v.descricaoTipoParcela,
            quantidadeApagar: v.quantidadeParcelaAPagar,
            quantidadePaga: v.quantidadeParcelaPaga,
            totalparcelaApagar: v.totalParcelaAPagar.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            totalparcelaPaga: v.totalParcelaPaga.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          }

          parcelasPorTipo.push(payload)
        })

        const totaisAReceber = []

        val.totaisareceber.forEach((v, idx) => {
          const payload = {
            n: idx,
            outrosvalores: v.outrosValores.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            valorjuros: v.valorJuros.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            valorprincipal: v.valorPrincipal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            saldodevedor: v.valorSaldoDevedor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            multa: v.valorMulta.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            totalparcelas: v.totalParcelas,
            parcelasatrasadas: v.totalParcelasAtrasadas,
          }

          totaisAReceber.push(payload)
        })

        const totaisRecebido = []

        val.totaisrecebido.forEach((v, idx) => {
          const payload = {
            n: idx,
            totaisparcelasrecebida: v.totalParcelas,
            parcelasrecebidasadiantadas: v.totalRecebidasAdiantadas,
            parcelasrecebidasatrasadas: v.totalRecebidasAtrasadas,
            valordescontoacrescimo: v.valorDescontoAcrescimo.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            valorjurosatraso: v.valorJurosAtraso.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            valormulta: v.valorMulta.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            valorprincipal: v.valorPrincipal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            valorrecebido: v.valorTotalRecebido.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          }

          totaisRecebido.push(payload)
        })

        const payload = {
          responsive_id: '',
          id: ind,
          codigoEmpresa: val.codigoEmpresa,
          numeroVenda: val.numeroVenda,
          codigoObra: val.codigoObra,
          empreendimento: val.desc_obra,
          identificador: val.identificador,
          totalparcelas: val.totalParcelas,
          quantidadeParcelaPaga: val.parcelasportipo.length > 0 ? val.parcelasportipo[0]?.quantidadeParcelaPaga : 0,
          valorTotal: val.parcelasportipo.length > 0 ? (val.parcelasportipo[0].totalParcelaAPagar + val.parcelasportipo[0].totalParcelaPaga).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }) : 0,
          valorPago: val.totaisrecebido[0].valorTotalRecebido.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          valorAPagar: val.totaisareceber[0].valorSaldoDevedor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          acoes: '+ detalhes',
          parcelasportipo: parcelasPorTipo,
          totaisareceber: totaisAReceber,
          totaisrecebido: totaisRecebido,
          datadecalculo: moment(String(val.dataCalculo)).format('DD/MM/YYYY'),
        }

        this.empreendimentosresumo.push(payload)
      })

      this.showTable = true

      this.$loading(false)
    }).catch(error => {
      console.log(error)
      if (error.response.status == 401) {
        Cookie.remove(Strings.cookie_token)
        Cookie.remove(Strings.cookie_username)
        this.$router.push('/pages/login')
      }

      console.log(error)
      this.$loading(false)
    }).finally(() => {
      this.$loading(false)
    })
  },
  methods: {
    closeDialogs(index) {
      this.$set(this.dialogs, index, false)
    },
    verParcelas(empresa, num_venda, obra, empreendimento, identificador) {
      this.$router.push({
        name: 'parcelas',
        params: {
          empresa, num_venda, obra, empreendimento, identificador,
        },
      })
    },
    gerarPDFDemonstrativoPgto(empresa, num_venda, obra) {
      this.$loading(true)

      const payload = {
        venda: num_venda,
        obra,
        empresa,
        ano_base: 2023,
      }

      UsuarioStore.gerarPDFDemonstrativoPgto(payload, Strings.imobiliariaId).then(result => {
        this.$router.push({ name: 'pdf-viewer', params: { pdfBase64: result.data.toString() } })
      }).catch(error => {
        if (error.response.status == 401) {
          Cookie.remove(Strings.cookie_token)
          Cookie.remove(Strings.cookie_username)
          this.$router.push('/pages/login')
        }

        console.log(error.response)
        this.$loading(false)
      }).finally(() => {
        this.$loading(false)
      })
    },
  },
  setup() {
    const momentF = Object
    const empreendimentosresumo = []
    const showTable = false
    const statusColor = {
      /* eslint-disable key-spacing */
      primary: 'primary',
      green: 'green',
      warning: 'warning',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: '#', value: 'id' },
        { text: 'Obra', value: 'codigoObra' },
        { text: 'Empreendimento', value: 'empreendimento' },
        { text: 'Identificador', value: 'identificador' },
        { text: 'Total Parcelas', value: 'totalparcelas' },
        { text: 'Total', value: 'valorTotal' },
        { text: 'Total à Pagar', value: 'valorAPagar' },
        { text: 'Ações', value: 'acoes' },
        { text: 'Parcelas', value: 'parcelas' },
      ],
      status: {
        1: 'primary',
        2: 'green',
        3: 'warning',
      },
      statusColor,
      momentF,

      // icons
      icons: {
        mdiOfficeBuildingMarkerOutline,
        mdiEyeOutline,
        mdiCash100,
      },
      empreendimentosresumo,
      showTable,
    }
  },
}
</script>
  <style scoped>
  .btn-bol{
    border-radius: 40px !important;
    width: 5rem !important;
    background: rgb(0, 136, 255);
    height: 1.6rem !important;
  }
  </style>
